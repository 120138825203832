import * as React from "react"
const Resources = () => {
  React.useEffect(() => {
   
    window.location.replace(`${process.env.GATSBY_BLOG_URL}/resources/`)
  }, [])
}




export default Resources
